import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

const TrustBox = ({ trustBoxRef }) => (
    <>
        <div
            ref={trustBoxRef} // We need a reference to this element to load the TrustBox in componentDidMount.
            className="trustpilot-widget mt-4" // Renamed this to className.
            data-locale="en-GB"
            data-template-id="5406e65db0d04a09e042d5fc"
            data-businessunit-id="5f4153095882480001d60ab4"
            data-style-height="28px"
            data-style-width="100%"
            data-theme="dark"
        >
            <a
                href="https://uk.trustpilot.com/review/youfibre.com"
                target="_blank"
                rel="noopener noreferrer"
            >
                Trustpilot
            </a>
        </div>
    </>
    
);

class TrustBoxWidget extends Component {
    constructor(props) {
        super(props);
        this.trustBoxRef = React.createRef();
    }
    componentDidMount() {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
        }
    }
    render() {
        return <TrustBox trustBoxRef={this.trustBoxRef} />;
    }
}
export default TrustBoxWidget;